import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import {
	Actions,
	Autocomplete,
	Button,
	checkAuthorities,
	Error,
	getVilles,
	hasAccess,
	injectSession,
	Input,
	InputFile,
	isCodePostalOk,
	isTelephoneOk,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	injectFormSnackbar
} from '@oceane/ui'
import { FormCheckbox, getStyles, injectToolbarData, Title, FormInput } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import {
	checkFileExist,
	getDecalageHoraire,
	modificationLocalisationSite
} from './services/sitesPhysiquesAction'
import { loadSite } from './services/injectSite'

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]

const styles = () => getStyles({
	buttonCharger: {
		marginTop: 19
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		marginRight: 5
	}
})

const validate = values => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = 'Le champ Libellé est obligatoire'
	}
	if (!values.adresseL1) {
		errors.adresseL1 = 'Le champ Ligne 1 est obligatoire'
	}
	if (!values.adresseCodePostal) {
		errors.adresseCodePostal = 'Le champ Code postal est obligatoire'
	}
	if (!values.adresseVille) {
		errors.adresseVille = 'Le champ Ville est obligatoire'
	}
	if (!values.itineraire) {
		errors.itineraire = 'Le champ Itinéraire est obligatoire'
	}
	if (values.adresseCodePostal && !isCodePostalOk(values.adresseCodePostal)) {
		errors.adresseCodePostal = 'Le champ Code postal ne doit comporter que des caractères alphanumériques ou des tirets'
	}
	if (values.telephone && !isTelephoneOk(values.telephone)) {
		errors.telephone = 'Le champ Téléphone ne doit comporter que des caractères numériques et les caractères ., -, +, espace'
	}
	if (values.telecopie && !isTelephoneOk(values.telecopie)) {
		errors.telecopie = 'Le champ Télécopie ne doit comporter que des caractères numériques et les caractères ., -, +, espace'
	}
	return errors
}

const DetailSitePhysique = ({
	                            classes,
	                            getVilles,
	                            match,
	                            profil,
	                            initialValues,
	                            file,
	                            change,
	                            plan,
	                            getDecalageHoraire,
	                            submitting,
	                            handleSubmit,
	                            modificationLocalisationSite,
	                            checkFileExist,
	                            snackError,
	                            getSitePhysique
}) => {
	const [disabled, setDisabled] = React.useState(false)
	const [customError, setCustomError] = React.useState(null)
	const [decalageH, setDecalageH] = React.useState([])
	const [hidePlan, setHidePlan] = React.useState(false)

	React.useEffect(() => {
		if (decalageH.length === 0) {
			getDecalageHoraire()
				.then((decalages) => setDecalageH(decalages.decalagesHoraires))
		}
	})

	if (!hasAccess(PROFILS_LOGISTIQUE, profil) && disabled === false) {
		setDisabled(true)
	} else if (hasAccess(PROFILS_LOGISTIQUE, profil) && disabled === true) {
		setDisabled(false)
	}

	const getFileExtension = (filename) => {
		return filename.substr((filename.lastIndexOf('.') + 1))
	}

	const modificationSite = (values) =>
		modificationLocalisationSite(values, profil)
			.then(() => {
				getSitePhysique()
				// Gestion visibilité fichier
				if (!!values.plan) {
					setHidePlan(false)
				}
			})
			.catch(e => {
				throw new SubmissionError(e)
			})

	const chargerFichier = () => {
		if (file) {
			const extension = getFileExtension(file[0].name)

			if (extension !== 'jpeg' && extension !== 'jpg' && extension !== 'gif' && extension !== 'bmp' && extension !== 'png' && extension !== 'tif' && extension !== 'tiff') {
				setCustomError(['L\'extension du fichier doit être au format .jpeg, .jpg, .gif, .bmp, .png, .tif, .tiff'])
				snackError('Une erreur est survenue')
			} else {
				setCustomError(null)
				setHidePlan(true)
			}
		} else {
			setCustomError(['Veuillez charger un fichier'])
			snackError('Une erreur est survenue')
		}
	}

	const downloadFile = (fichierNom) => {
		checkFileExist(initialValues.id, fichierNom)
			.then(() =>
				download(`/files/localisationSite/${fichierNom}`)
			)
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	const supprimerFichier = () => {
		setHidePlan(true)
		change('plan', null)
		// On indique qu'il faudra supprimer le fichier
		change('fichierASupprimer', true)
	}

	return (
		<React.Fragment>
			<Title value="Détail" />
			<Error error={customError} />
			<ResponsiveForm>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="libelle"
							name="libelle"
							label="Libellé *"
							component={Input}
							inputProps={{
								maxLength: 50
							}}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="decalageMetropole"
							name="decalageMetropole"
							label="Décalage horaire / métropole (hiver) *"
							component={Select}
						>
							{decalageH.length !== 0 && decalageH.map(decalage =>
								<MenuItem key={decalage.id} value={decalage.id}>
									{decalage.libelle}
								</MenuItem>
							)}
						</Field>
					</Grid>
				</Grid>
				<Field
					id="heureEte"
					name="heureEte"
					label="Gestion heure été"
					component={FormCheckbox}
				/>
				<Field
					id="adresseL1"
					name="adresseL1"
					label="Ligne 1 *"
					component={Input}
				/>
				<Field
					id="adresseL2"
					name="adresseL2"
					label="Ligne 2"
					component={Input}
				/>
				<Field
					id="adresseL3"
					name="adresseL3"
					label="Ligne 3"
					component={Input}
				/>
				<Field
					id="adresseCodePostal"
					name="adresseCodePostal"
					label="Code postal *"
					component={Input}
				/>
				<Field
					id="adresseVille"
					name="adresseVille"
					component={Autocomplete}
					getSuggestion={(length, value) => getVilles(value)}
					label="Ville *"
					canLoadIfEmpty
				/>
				<Field
					id="adressePays"
					name="adressePays"
					label="Pays"
					component={Input}
				/>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="telephone"
							name="telephone"
							label="Téléphone"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="urlServeurLocal"
							name="urlServeurLocal"
							label="URL serveur local"
							component={Input}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="telecopie"
							name="telecopie"
							label="Télécopie"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="urlAnnexe"
							name="urlAnnexe"
							label="URL local annexes"
							component={Input}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
				<Field
					id="itineraire"
					name="itineraire"
					label="Itinéraire *"
					component={FormInput}
					fullWidth
					multiline
					inputProps={{
						maxLength: 1024
					}}
				/>
				<Grid container alignItems="flex-start">
					<Grid item>
						<InputFile
							name="file"
							id="file"
							label="Plan"
							accept="image/jpg, image/png, image/gif, image/jpeg, image/tif, image/tiff, image/bmp"
						/>
					</Grid>
					<Grid item>
						<Button
							color="primary"
							className={classes.buttonCharger}
							onClick={() => chargerFichier()}
						>
							Charger
						</Button>
					</Grid>
				</Grid>

				{(plan != null && !hidePlan) && <Grid container>
					<Grid item>
						<Typography className={classes.link} onClick={() => downloadFile(plan)}>{plan}</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Button
							color="primary"
							onClick={() => supprimerFichier()}
						>
							Supprimer
						</Button>
					</Grid>
				</Grid>}
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(modificationSite)}
					>
						Enregistrer
					</Button>
				</Actions>
			</ResponsiveForm>
		</React.Fragment>
	)
}

const mapStateToProps = (state, { location, site }) => {
	const { file, plan } = formValueSelector('ModifierSitePhysiqueForm')(state, 'file', 'plan')

	return ({
		initialValues: {
			...site,
			file: null
		},
		file,
		plan
	})
}

DetailSitePhysique.propTypes = {
	classes: PropTypes.object,
	match: PropTypes.object,
	profil: PropTypes.number,
	initialValues: PropTypes.object,
	file: PropTypes.object,
	plan: PropTypes.string,
	submitting: PropTypes.bool,
	getDecalageHoraire: PropTypes.func,
	handleSubmit: PropTypes.func,
	modificationLocalisationSite: PropTypes.func,
	checkFileExist: PropTypes.func,
	snackError: PropTypes.func,
	getSitePhysique: PropTypes.func,
	getVilles: PropTypes.func,
	change: PropTypes.func,
}

const actions = {
	getVilles,
	modificationLocalisationSite,
	checkFileExist,
	getDecalageHoraire
}

export default compose(
	loadSite,
	checkAuthorities(
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	injectSession,
	reduxForm({
		form: 'ModifierSitePhysiqueForm',
		validate,
		enableReinitialize: true
	}),
	injectToolbarData({ title: 'Gestion de la localisation du site' }),
	withStyles(styles),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' })
)(DetailSitePhysique)
